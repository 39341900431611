import React from "react";

import async from "../components/Async";

import {
  User,
  Users
} from "react-feather";

import {
  EuroSymbol as InvoiceIcon,
  InsertDriveFile as CommercialAgreementsIcon,
  CalendarToday as EventsIcon,
  AttachFile as LegalIcon,
  Contacts as ContactsIcon
} from "@material-ui/icons";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
//const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const PageNetworkError = async(() => import("../pages/auth/PageNetworkError"));

// Pages components
const Profile = async(() => import("../pages/pages/Profile"));
const Umbrella = async(() => import("../pages/pages/Umbrella"));
const Contacts = async(() => import("../pages/pages/Contacts"));
const Events = async(() => import("../pages/pages/Events"));
const CommercialAgreementSigningList = async(() => import("../pages/pages/CommercialAgreementSigningList"));
const CommercialAgreementActiveList = async(() => import("../pages/pages/CommercialAgreementActiveList"));
const CommercialAgreementClosedList = async(() => import("../pages/pages/CommercialAgreementClosedList"));
const CommercialAgreementDetails = async(() => import("../pages/pages/CommercialAgreementDetails"));
const LegalDocumentsList = async(() => import("../pages/pages/LegalDocuments"));
const SalesInvoiceWaitingList = async(() => import("../pages/pages/SalesInvoiceWaitingList"));
const SalesInvoicePaidList = async(() => import("../pages/pages/SalesInvoicePaidList"));
const SalesInvoiceCanceledList = async(() => import("../pages/pages/SalesInvoiceCanceledList"));
const SalesInvoiceDetails = async(() => import("../pages/pages/SalesInvoiceDetails"));

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/login",
      name: "Connexion",
      component: SignIn
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/500",
      name: "Erreur interne",
      component: Page500
    },
    {
      path: "/networkerror",
      name: "Erreur réseau",
      component: PageNetworkError
    }
  ]
};

const profileRoutes = {
  id: "profile",
  path: "/profil",
  icon: <User />,
  component: Profile,
  children: null
}

const umbrellaRoutes = {
  id: "umbrella",
  path: "/",
  icon: <User />,
  component: Umbrella,
  children: null,
  isHome: true
};

const contactsRoutes = {
  id: "openwork-contacts",
  path: "/contacts",
  icon: <ContactsIcon />,
  component: Contacts,
  children: null
};

const legalRoutes = {
  id: "legal-kit",
  path: "/legal",
  icon: <LegalIcon />,
  component: LegalDocumentsList,
  children: null
};

const commercialAgreementList = {
  id: "commercialAgreement",
  path: "/commercial-agreements",
  icon: <CommercialAgreementsIcon />,
  children: [
    {
      path: "/commercial-agreements/sign",
      name: "ca-signing",
      component: CommercialAgreementSigningList
    },
    {
      path: "/commercial-agreements",
      name: "ca-actives",
      component: CommercialAgreementActiveList
    },
    {
      path: "/commercial-agreements/closed",
      name: "ca-closed",
      component: CommercialAgreementClosedList
    }
  ]
};

const commercialAgreementDetails = {
  id: "CommercialAgreement",
  path: "/commercial-agreements/:no",
  icon: <CommercialAgreementsIcon />,
  component: CommercialAgreementDetails,
  children: null
};

const salesInvoiceList = {
  id: "Invoices",
  path: "/sales-invoice",
  icon: <InvoiceIcon />,
  children: [
    {
      path: "/sales-invoice",
      name: "invoices-waiting",
      component: SalesInvoiceWaitingList
    },
    {
      path: "/sales-invoice/paid",
      name: "invoices-paid",
      component: SalesInvoicePaidList
    },
    {
      path: "/sales-invoice/canceled",
      name: "invoices-canceled",
      component: SalesInvoiceCanceledList
    }
  ]
};

const salesInvoiceDetails = {
  id: "invoice",
  path: "/sales-invoice/:no",
  icon: <InvoiceIcon />,
  component: SalesInvoiceDetails,
  children: null
}

const eventsRoutes = {
  id: "events",
  path: "/events",
  icon: <EventsIcon />,
  component: Events,
  children: null
};

export const dashboard = [
    umbrellaRoutes,
    contactsRoutes,
    legalRoutes,
    commercialAgreementList,
    commercialAgreementDetails,
    salesInvoiceList,
    profileRoutes,
    salesInvoiceDetails,
    eventsRoutes
];

export const auth = [authRoutes];

export default [
  umbrellaRoutes,
  contactsRoutes,
  legalRoutes,
  commercialAgreementList,
  salesInvoiceList,
  eventsRoutes
];
